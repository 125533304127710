import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  mixins: {
    gutters: () => { return {padding: "12px 24px 16px 24px"}}
  },
  palette: {
    type: "light",
    primary: {
      light: "#DBDBDB",
      main: "#403B3D",
      dark: "#000000",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#2c2c2c",
      main: "#000000",
      dark: "#000000",
      contrastText: "#ffffff",
    },
    grey: {
      50: "#FAFAFA",
      100: "#F3F3F3",
      200: "#EAEAEA",
      300: "#DBDBDB",
      400: "#B7B7B7",
      500: "#989898",
      600: "#726E70",
      700: "#5F5A5B",
      800: "#403B3D",
      900: "#1F1D1D",
    },
    common: {
      white: "#FFFFFF",
    },
    error: {
      main: "#ED380C",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  // usage theme.spacing(1)
  //spacing: factor => `${1 * factor}rem`, // (Bootstrap strategy)
  overrides: {
    MuiCard: {
      root: {
        borderRadius: 0,
      },
    },
    MuiCardActions: {
      root: {
        padding: "12px 24px 16px 24px"
      }
    },
    MuiDialogActions: {
      root: {
        padding: "12px 24px 16px 24px"
      }
    },
    MuiDrawer: {
      paper: {
        backgroundColor: "#333",
        color: "#adadad",
      },
    },
    MuiToolbar: {
      root: {
        color: "#fff",
        backgroundColor: "#000",
      },
    },
    MuiContainer: {
      root: {
        backgroundColor: "#fafafa",
      },
    },
    MuiTab: {
      textColorPrimary: {
        color: "#adadad",
      },
      root: {
        // "&:hover": {
        //   backgroundColor: '#999',
        //   color: '#fff',
        // },
        "&$selected": {
          "&$textColorPrimary": {
            color: "#fff",
          },
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: "#fff",
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: "25px",
        paddingRight: "25px",
        paddingTop: "10px",
        paddingBottom: "10px",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: "auto",
        color: "#989898",
        marginRight: "10px",
      },
    },
    MuiListItemText: {
      root: {
        color: "#989898",
      },
    },
    MuiButton: {
      textPrimary: {
        backgroundColor: "#000000",
        color: "#FFF",
        "&:hover": {
          backgroundColor: "#726E70",
        },
        "&:disabled, &[disabled]": {
          backgroundColor: "#555",
          color: "#CCC",
        },
        ["@media (hover: none)"]: {
          backgroundColor: "#000 !important",
        }
      },
      outlinedSecondary: {
        color: "#1F1D1D",
        background: "#FAFAFA",
        borderColor: "#DBDBDB",
        fontWeight: "bold",
        borderRadius: "2px",
        "&:hover": {
          borderColor: "#DBDBDB",
          background: "#F3F3F3",
        },
      },
    },
    MuiChip: {
      root: {
        marginBottom: "0.25em",
        marginRight: "0.25em",
      },
    },
  },

  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      textTransform: "uppercase",
      fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
      fontWeight: 700,
      color: "#FFFFFF",
      fontSize: "26px",
      marginBottom: "1rem",
    },
    h2: {
      textTransform: "uppercase",
      fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
      fontWeight: 700,
      fontSize: "22px",
    },
    h3: {
      textTransform: "uppercase",
      fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
      fontWeight: 700,
      fontSize: "18px",
    },
    h4: {
      textTransform: "uppercase",
      fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
      fontWeight: 700,
    },
    h5: {
      textTransform: "uppercase",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 700,
    },
    h6: {
      textTransform: "uppercase",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 700,
    },
    overline: {
      textTransform: "uppercase",
      fontFamily: '"Roboto Medium", "Helvetica", "Arial", sans-serif',
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: "10px",
      lineHeight: "16px",
      letterSpacing: "1.5px",
    },
  },
});

export default theme;
