import React from 'react';
import { SdCard, SdCardEmpty } from '../mui-icons';
import { IconWrapper, StatusIconProps } from './base-wrapper';
import theme from '../../theme';

export const StorageIcon = ({ operationStatus, ...props }: StatusIconProps) => {
  const getLabelColor = () => {
    if (operationStatus?.storage?.isCritical) {
      return theme.palette.error.main;
    } else if (operationStatus?.storage?.isLow) {
      return theme.palette.warning.main;
    }

    return theme.palette.text.primary;
  }

  const getIcon = () => {
    if (operationStatus?.storage?.isCritical) {
      return (
        <SdCardEmpty
          style={{
            color: theme.palette.error.main,
          }}
        />
      );
    } else if (operationStatus?.storage?.isLow) {
      return (
        <SdCard
          style={{
            color: theme.palette.warning.main,
          }}
        />
      );
    } else {
      return <SdCard />;
    }
  };

  return (
    <IconWrapper
      icon={getIcon()}
      title="Storage"
      label={`${operationStatus?.storage?.percentFree}%`}
      color={getLabelColor()}
      {...props}
    />
  );
};
