import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgSdCard = (props: React.SVGProps<SVGSVGElement>) =>
  React.createElement(
    SvgIcon,
    props,
    <path
      d="M17 20c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2H9L5 8v10c0 1.103.897 2 2 2h10zm0-1.5H7a.501.501 0 01-.5-.5V8.622L9.622 5.5H17c.275 0 .5.225.5.5v12c0 .275-.225.5-.5.5zm-.5-9v-3H15v3h1.5zm-2.5 0v-3h-1.5v3H14zm-2.5 0v-3H10v3h1.5z"
      fill="currentColor"
      fillRule="nonzero"
    />
  );

export default SvgSdCard;
