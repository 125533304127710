import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgSignalZero = (props: React.SVGProps<SVGSVGElement>) =>
  React.createElement(
    SvgIcon,
    props,
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M21.281 19.807l.614-.79a.5.5 0 00-.088-.701L21 17.692V5a1 1 0 00-1-1h-1a1 1 0 00-1 1v10.373l-2-1.545V9a1 1 0 00-1-1h-1a1 1 0 00-1 1v2.51L3.42 4.104a.5.5 0 00-.7.088l-.615.79a.5.5 0 00.088.701l18.386 14.21a.5.5 0 00.702-.087zM10 20a1 1 0 001-1v-3.981L8.052 12.74C8.03 12.825 8 12.908 8 13v6a1 1 0 001 1h1zm-5 0a1 1 0 001-1v-2a1 1 0 00-1-1H4a1 1 0 00-1 1v2a1 1 0 001 1h1zm10 0a1 1 0 001-1v-.117l-3-2.318V19a1 1 0 001 1h1z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  );

export default SvgSignalZero;
