import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgBilling = (props: React.SVGProps<SVGSVGElement>) =>
  React.createElement(
    SvgIcon,
    props,
    <path
      d="M20.559 19.75c.935 0 1.691-.756 1.691-1.688V5.687c0-.931-.756-1.687-1.691-1.687H3.691C2.756 4 2 4.756 2 5.687v12.375c0 .932.756 1.688 1.691 1.688h16.868zm0-12.375H3.691V5.898c0-.116.095-.21.21-.21h16.447c.116 0 .211.094.211.21v1.477zm-.211 10.687H3.902a.212.212 0 01-.211-.21v-5.977h16.868v5.977c0 .116-.095.21-.211.21zm-12.02-1.687c.232 0 .422-.19.422-.422v-1.406a.423.423 0 00-.422-.422H5.797a.423.423 0 00-.422.422v1.406c0 .232.19.422.422.422h2.531zm6.75 0c.232 0 .422-.19.422-.422v-1.406a.423.423 0 00-.422-.422h-4.781a.423.423 0 00-.422.422v1.406c0 .232.19.422.422.422h4.781z"
      fill="currentColor"
      fillRule="nonzero"
    />
  );

export default SvgBilling;
