import React from "react";
import { makeStyles } from "@material-ui/core";
import theme from "../../theme";
import {
  BatteryEmpty,
  BatteryLow,
  BatteryFull,
  WifiThree,
  WifiTwo,
  WifiOne,
  SignalFour,
  SignalThree,
  SignalTwo,
  SignalOne,
  SignalZero,
  SdCardEmpty,
  SdCard,
  WifiZero
} from "../mui-icons";

import { SignalCellularOff, SignalWifiOff } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  cameraOperationStatusList: {
    listStyle: "none",
    margin: 0,
    padding: 0,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start"
    }
  },
  cameraOperationStatusItem: {
    fontSize: "0.8em",
    color: theme.palette.common.black,
    display: "flex",
    flexBasis: "25%",
    [theme.breakpoints.up("md")]: {
      flexBasis: "50%"
    }
  },
  cameraOperationStatusIcon: {
    marginRight: theme.spacing(0.5),
    "& svg": {
      width: "0.75em",
      height: "0.75em"
    }
  }
}));

export const StatusIcons = ({ camera }: { camera: any }) => {
  const classes = useStyles();

  return (
    <ul className={classes.cameraOperationStatusList}>
      <li className={classes.cameraOperationStatusItem}>
        <div className={classes.cameraOperationStatusIcon} title="Battery">
          {(() => {
            if (camera.operationStatus.battery.isCritical) {
              return (
                <BatteryEmpty
                  style={{
                    color: theme.palette.error.main
                  }}
                />
              );
            } else if (camera.operationStatus.battery.isLow) {
              return (
                <BatteryLow
                  style={{
                    color: theme.palette.warning.main
                  }}
                />
              );
            } else {
              return <BatteryFull />;
            }
          })()}
        </div>
        {camera.operationStatus.battery.percentRemaining}%
      </li>
      <li className={classes.cameraOperationStatusItem}>
        <div className={classes.cameraOperationStatusIcon} title="Storage">
          {(() => {
            if (camera.operationStatus.storage.isCritical) {
              return (
                <SdCardEmpty
                  style={{
                    color: theme.palette.error.main
                  }}
                />
              );
            } else if (camera.operationStatus.storage.isLow) {
              return (
                <SdCard
                  style={{
                    color: theme.palette.warning.main
                  }}
                />
              );
            } else {
              return <SdCard />;
            }
          })()}
        </div>
        {camera.operationStatus.storage.percentFree}%
      </li>
      {camera.operationStatus.wifi?.strength >= 0 ?
        <li className={classes.cameraOperationStatusItem}>
          <div className={classes.cameraOperationStatusIcon} title="Wifi signal">
            {(() => {
              switch (camera.operationStatus.wifi?.strength) {
                case 4:
                  return <WifiThree />;
                case 3:
                  return <WifiThree />;
                case 2:
                  return (
                    <WifiTwo
                      style={{
                        color: theme.palette.warning.main
                      }}
                    />
                  );
                case 1:
                  return (
                    <WifiOne
                      style={{
                        color: theme.palette.error.main
                      }}
                    />
                  );
                default:
                  return (
                    <WifiZero
                      style={{
                        color: theme.palette.error.main
                      }}
                    />
                  );
              }
            })()}
          </div>
          {camera.operationStatus.wifi?.percentStrength}%
        </li>
        : null
      }
      {camera.operationStatus.cellular?.strength >= 0 ?
        <li className={classes.cameraOperationStatusItem}>
          <div className={classes.cameraOperationStatusIcon} title="Cellular signal">
            {(() => {
              switch (camera.operationStatus.cellular?.strength) {
                case 4:
                  return <SignalFour/>;
                case 3:
                  return <SignalThree/>;
                case 2:
                  return (
                    <SignalTwo
                      style={{
                        color: theme.palette.warning.main
                      }}
                    />
                  );
                case 1:
                  return (
                    <SignalOne
                      style={{
                        color: theme.palette.error.main
                      }}
                    />
                  );
                default:
                  return (
                    <SignalZero
                      style={{
                        color: theme.palette.error.main
                      }}
                    />
                  );
              }
            })()}
          </div>
          {camera.operationStatus.cellular?.percentStrength}%
        </li>
        : null
      }
    </ul>
  );
}
