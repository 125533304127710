import {
  BadRequestModel,
  ForbiddenModel,
  GoneModel,
  NotFoundModel,
  UnauthorizedModel,
} from '../isc-api';

export class TwoFactorException extends Error {
  challengeId: string;

  constructor(challengeId: string) {
    super('Two factor authentication required');
    this.challengeId = challengeId;
  }
}

export class ApiException extends Error {
  status: number;
  body?: string;
  path?: string;

  constructor(
    status: number,
    path: string,
    message: string = 'An unexpected API exception occurred',
    body?: string
  ) {
    super(message);
    this.path = path;
    this.status = status;
    this.body = body;
  }
}

export class EmptyException extends ApiException {
  constructor(path: string, message: string = 'Empty response body from API') {
    super(204, path, message);
  }
}

export class UnauthorizedException extends ApiException {
  attempts?: number;

  constructor(path: string, attempts?: number) {
    super(401, path);
    this.attempts = attempts;
  }
}

export class NotFoundException extends ApiException {
  constructor(path: string, message: string = 'Server Returned 404 Not Found') {
    console.log(404, path, message);
    super(404, path, message);
  }
}

export class ForbiddenException extends ApiException {
  constructor(path: string, message: string = 'Server Returned 403 Forbidden') {
    super(403, path, message);
  }
}

export class GoneException extends ApiException {
  constructor(path: string, message: string = 'Server Returned GoneException') {
    super(410, path, message);
  }
}

// This is not in the API def
export type BadRequestDetails = {
  code?: string
  errors?: Record<string, string[]>
  message?: string
  status?: number
  title?: string
}
export class BadRequestException extends ApiException {
  data: BadRequestDetails;
  errors: Record<string, string[]>;
  constructor(path: string, data: BadRequestDetails) {
    super(400, path, 'Bad request');
    this.data = data;
    this.errors = data?.errors || {}
  }
}

export type ErrorModels =
  | BadRequestModel
  | UnauthorizedModel
  | ForbiddenModel
  | NotFoundModel
  | GoneModel;

export type ErrorTypes =
  | BadRequestException
  | UnauthorizedException
  | ForbiddenException
  | NotFoundException
  | GoneException
  | ApiException;

export type AmplifyAPIError = {
  response?: {
    data?:
      | BadRequestModel
      | UnauthorizedModel
      | ForbiddenModel
      | NotFoundModel
      | GoneModel;
    status?: number;
  };
};

export type Pagination = {
  page: number;
  pageSize: number;
  total: number;
  pages: number;
};

/**
 * Used to populate form errors
 */
export type ServerValidationErrors = Record<string, string[]>;
