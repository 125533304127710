import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgCamerasPortal = (props: React.SVGProps<SVGSVGElement>) =>
  React.createElement(
    SvgIcon,
    props,
    <defs>
      <path id="cameras-portal_svg__a" d="M0 .121h17.043V19H0z" />
    </defs>,
    <g fill="none" fillRule="evenodd">
      <g transform="translate(3 2.879)">
        <mask id="cameras-portal_svg__b" fill="#fff">
          <use xlinkHref="#cameras-portal_svg__a" />
        </mask>
        <path
          d="M1.573.12C.996.12.524.594.524 1.17v1.075A.525.525 0 000 2.769v4.457c0 .29.235.525.524.525v7.158a.525.525 0 000 1.049v1.993c0 .577.472 1.049 1.05 1.049h14.42c.576 0 1.05-.472 1.05-1.05V1.17c0-.576-.474-1.05-1.05-1.05H1.574zM2.28 17.13h13.01V1.99H2.278v15.138z"
          fill="currentColor"
          mask="url(#cameras-portal_svg__b)"
        />
      </g>
      <path
        d="M11.757 14.981a4.123 4.123 0 004.116-4.116 4.107 4.107 0 00-4.116-4.116 4.107 4.107 0 00-4.116 4.116 4.106 4.106 0 004.116 4.116m0-6.743a2.656 2.656 0 012.653 2.652 2.642 2.642 0 01-2.653 2.654 2.657 2.657 0 01-2.653-2.654 2.642 2.642 0 012.653-2.652M7.757 6.393c0 .908-1.363.908-1.363 0 0-.909 1.363-.909 1.363 0M17.12 6.393c0 .908-1.363.908-1.363 0 0-.909 1.363-.909 1.363 0"
        fill="currentColor"
      />
    </g>
  );

export default SvgCamerasPortal;
