import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgBatteryEmpty = (props: React.SVGProps<SVGSVGElement>) =>
  React.createElement(
    SvgIcon,
    props,
    <path
      d="M21.188 20a.566.566 0 00.39-.188l.313-.39a.582.582 0 00.11-.313.566.566 0 00-.188-.39L3.125 4.109A.582.582 0 002.813 4a.566.566 0 00-.39.187l-.313.39a.582.582 0 00-.11.314c0 .132.084.307.188.39l18.687 14.61c.076.06.216.109.313.109zm-.307-3.913c.066-.155.12-.417.12-.585V15h.25a.75.75 0 00.75-.75v-4.5a.75.75 0 00-.75-.75H21v-.5A1.5 1.5 0 0019.5 7H9.257l1.918 1.5H19.5v2h1v3h-1v1.508l1.38 1.079zM14.743 17l-1.919-1.5H3.5V8.21l-1.024-.8C2.214 7.654 2 8.142 2 8.5v7A1.5 1.5 0 003.5 17h11.243z"
      fill="currentColor"
      fillRule="nonzero"
    />
  );

export default SvgBatteryEmpty;
