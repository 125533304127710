import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgDetectFace = (props: React.SVGProps<SVGSVGElement>) =>
  React.createElement(
    SvgIcon,
    props,
    <defs>
      <filter id="detect-face_svg__a">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"
        />
      </filter>
    </defs>,
    <g filter="url(#detect-face_svg__a)" fill="none" fillRule="evenodd">
      <g fill="currentColor">
        <path d="M1.12 6.72A1.12 1.12 0 002.24 5.6V2.24H5.6A1.12 1.12 0 105.6 0H2.24A2.239 2.239 0 000 2.24V5.6a1.12 1.12 0 001.12 1.12zM2.24 26.88H5.6a1.12 1.12 0 100-2.24H2.24v-3.36a1.12 1.12 0 10-2.24 0v3.36a2.238 2.238 0 002.24 2.24zM26.88 24.64v-3.36a1.12 1.12 0 10-2.24 0v3.36h-3.36a1.12 1.12 0 100 2.24h3.36a2.238 2.238 0 002.24-2.24zM21.28 0a1.12 1.12 0 100 2.24h3.36V5.6a1.12 1.12 0 102.24 0V2.24A2.238 2.238 0 0024.64 0h-3.36zM6.72 19.04a1.12 1.12 0 102.24 0 4.48 4.48 0 018.96 0 1.12 1.12 0 102.24 0 6.721 6.721 0 00-3.478-5.887 4.48 4.48 0 10-6.484 0A6.72 6.72 0 006.72 19.04zm4.48-8.96c0-.906.545-1.724 1.383-2.07a2.238 2.238 0 012.44.486c.642.641.834 1.604.486 2.442a2.239 2.239 0 01-4.31-.857l.001-.001z" />
      </g>
    </g>
  );

export default SvgDetectFace;
