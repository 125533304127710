import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgHelpCircle = (props: React.SVGProps<SVGSVGElement>) =>
  React.createElement(
    SvgIcon,
    props,
    <g fill="currentColor" fillRule="evenodd">
      <path
        d="M11.807 5.74c2.781 0 4.512 1.438 4.512 3.627 0 1.45-.577 2.223-1.923 3.169l-.378.259c-1.01.687-1.338 1.075-1.373 1.863l-.003.152H10.53c0-1.685.647-2.596 2.097-3.62l.385-.265c.96-.652 1.195-.94 1.195-1.558 0-.927-.708-1.516-2.4-1.516-1.28 0-2.132.709-2.204 1.708l-.004.138H7.487c0-2.296 1.866-3.958 4.32-3.958z"
        fillRule="nonzero"
      />
      <path d="M12.904 17.387a1.318 1.318 0 11-2.637 0 1.318 1.318 0 012.637 0" />
      <path
        d="M11.902 0C5.33 0 0 5.33 0 11.902c0 6.574 5.329 11.904 11.902 11.904 6.574 0 11.904-5.33 11.904-11.904C23.806 5.33 18.476 0 11.902 0zm0 2.112c5.408 0 9.792 4.383 9.792 9.79 0 5.408-4.384 9.792-9.792 9.792-5.407 0-9.79-4.384-9.79-9.792 0-5.407 4.383-9.79 9.79-9.79z"
        fillRule="nonzero"
      />
    </g>
  );

export default SvgHelpCircle;
