import React, { useState, useEffect } from 'react';
import { LinearProgress, makeStyles, Fade } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  initial: {
    opacity: 0,
    transition: 'opacity 0.3s',
    margin: theme.spacing(5, 'auto'),
    maxWidth: '380px',
  },
  show: {
    opacity: 1,
  },
}));

type ProgressBarProps = {
  delay?: number;
  className?: string;
};

export const ProgressBar = ({ delay = 250, className }: ProgressBarProps) => {
  const classes = useStyles();
  const [visibility, setVisibility] = useState<VisibilityState>('hidden');

  useEffect(() => {
    setTimeout(() => setVisibility('visible'), delay);
  });

  return (
    <div
      className={clsx(
        classes.initial,
        className,
        visibility === 'visible' && classes.show
      )}
    >
      <LinearProgress style={{ visibility }} />
    </div>
  );
};
