import React from 'react';
import {
  SignalFour,
  SignalThree,
  SignalTwo,
  SignalOne,
  SignalZero,
} from '../mui-icons';
import { IconWrapper, StatusIconProps } from './base-wrapper';
import theme from '../../theme';

export const CellularIcon = ({
  operationStatus,
  ...props
}: StatusIconProps) => {
  const getIcon = () => {
    switch (operationStatus?.cellular?.strength) {
      case 5:
      case 4:
        return <SignalFour />;
      case 3:
        return <SignalThree />;
      case 2:
        return (
          <SignalTwo
            style={{
              color: theme.palette.warning.main,
            }}
          />
        );
      case 1:
        return (
          <SignalOne
            style={{
              color: theme.palette.error.main,
            }}
          />
        );
      default:
        return (
          <SignalZero style={{
            color: theme.palette.error.main,
          }}
        />
      );
    }
  };

  return operationStatus?.cellular?.percentStrength >= 0 ? (
    <IconWrapper
      icon={getIcon()}
      title="Cellular signal"
      label={`${operationStatus?.cellular?.percentStrength}%`}
      color={operationStatus?.cellular?.isWeakSignal ? theme.palette.error.main : theme.palette.text.primary}
      {...props}
    />
  ) : null;
};
