import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgSignalThree = (props: React.SVGProps<SVGSVGElement>) =>
  React.createElement(
    SvgIcon,
    props,
    <g fill="currentColor" fillRule="nonzero">
      <path
        d="M20 20a1 1 0 001-1V5a1 1 0 00-1-1h-1a1 1 0 00-1 1v14a1 1 0 001 1h1zm-5 0a1 1 0 001-1V9a1 1 0 00-1-1h-1a1 1 0 00-1 1v10a1 1 0 001 1h1zm-5 0a1 1 0 001-1v-6a1 1 0 00-1-1H9a1 1 0 00-1 1v6a1 1 0 001 1h1zm-5 0a1 1 0 001-1v-2a1 1 0 00-1-1H4a1 1 0 00-1 1v2a1 1 0 001 1h1z"
        opacity={0.2}
      />
      <path d="M15 20a1 1 0 001-1V9a1 1 0 00-1-1h-1a1 1 0 00-1 1v10a1 1 0 001 1h1zm-5 0a1 1 0 001-1v-6a1 1 0 00-1-1H9a1 1 0 00-1 1v6a1 1 0 001 1h1zm-5 0a1 1 0 001-1v-2a1 1 0 00-1-1H4a1 1 0 00-1 1v2a1 1 0 001 1h1z" />
    </g>
  );

export default SvgSignalThree;
