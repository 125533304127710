import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgWifiThree = (props: React.SVGProps<SVGSVGElement>) =>
  React.createElement(
    SvgIcon,
    props,
    <g fill="none" fillRule="evenodd">
      <path
        d="M20.782 10.62l1.07-1.062a.497.497 0 00-.011-.718c-5.537-5.12-14.148-5.119-19.682 0a.497.497 0 00-.01.718l1.07 1.062c.191.19.5.194.7.012 4.56-4.178 11.602-4.179 16.163 0 .2.182.508.178.7-.012zm-3.507 3.553l1.076-1.062a.494.494 0 00-.018-.723c-3.601-3.185-9.069-3.182-12.666 0a.494.494 0 00-.018.723l1.076 1.062a.51.51 0 00.689.025c2.623-2.267 6.554-2.262 9.172 0 .2.173.501.16.689-.025zM12 19a2 2 0 100-4 2 2 0 000 4z"
        fill="currentColor"
        fillRule="nonzero"
      />
      <path d="M0 0h24v24H0z" />
    </g>
  );

export default SvgWifiThree;
