import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgBatteryFull = (props: React.SVGProps<SVGSVGElement>) =>
  React.createElement(
    SvgIcon,
    props,
    <path
      d="M19.5 17a1.5 1.5 0 001.5-1.5V15h.25a.75.75 0 00.75-.75v-4.5a.75.75 0 00-.75-.75H21v-.5A1.5 1.5 0 0019.5 7h-16A1.5 1.5 0 002 8.5v7A1.5 1.5 0 003.5 17h16zm0-1.5h-16v-7h16v2h1v3h-1v2zM18 14v-4H5v4h13z"
      fill="currentColor"
      fillRule="nonzero"
    />
  );

export default SvgBatteryFull;
