import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgDetectVehicle = (props: React.SVGProps<SVGSVGElement>) =>
  React.createElement(
    SvgIcon,
    props,
    <g>
      <g>
        <path d="M35.577 7.272L26.71 6.124 20.863.26a.884.884 0 00-.626-.26H7.15c-.242 0-.474.1-.642.275L.298 6.798c-.04.044-.064.1-.095.148-.027.043-.062.08-.082.127a.902.902 0 00-.068.34v4.819a2.818 2.818 0 002.81 2.818h.485c.292 2.057 2.042 3.65 4.174 3.65 2.131 0 3.882-1.593 4.172-3.652l.005.002h12.94c.292 2.057 2.043 3.65 4.174 3.65 2.132 0 3.883-1.593 4.173-3.65h.853a2.52 2.52 0 002.514-2.521l-.001-4.375a.891.891 0 00-.775-.882zM24.155 6.078H17.13V1.78h2.738l4.287 4.3zM10.483 1.78h4.873v4.3H8.308l2.144-4.19c.019-.035.019-.073.032-.11zM7.52 16.921a2.465 2.465 0 010-4.93 2.464 2.464 0 010 4.929zm21.29 0a2.465 2.465 0 010-4.93 2.463 2.463 0 012.458 2.465 2.463 2.463 0 01-2.458 2.465zm5.768-4.392a.742.742 0 01-.74.742h-.984c-.515-1.76-2.123-3.058-4.043-3.058-1.921 0-3.528 1.297-4.043 3.058h-13.07c-.045 0-.084.019-.127.025-.507-1.774-2.12-3.083-4.05-3.083-1.928 0-3.54 1.305-4.05 3.077-.031-.004-.058-.02-.091-.02h-.517a1.038 1.038 0 01-1.035-1.038v-3.93h1.996a.886.886 0 00.886-.888.887.887 0 00-.886-.89h-.81L7.53 1.78h.984L6.066 6.563a.892.892 0 00.788 1.296H26.24l8.336 1.08.002 3.59z" />
        <path d="M18.993 8.8h-1.58c-.476 0-.86.492-.86 1.1 0 .608.385 1.1.86 1.1h1.58c.477 0 .86-.492.86-1.1 0-.608-.385-1.1-.86-1.1z" />
      </g>
    </g>
  );

export default SvgDetectVehicle;
