import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    backgroundColor: 'transparent',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    overflow: 'hidden',
    minHeight: '100vh',
    '&::before': {
      content: '""',
      opacity: '0.9',
      zIndex: '-1',
      position: 'absolute',
      left: '0',
      right: '0',
      height: '375px',
      marginTop: '-85px',
      background: theme.palette.grey[900],
      transform: 'skewY(2.75deg)',
      transformOrigin: '100%',
      transition: 'all 0.2s',
    },
  },
  contentHome: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  contentHomeShapeFade: {
    '&::before': {
      opacity: 0,
      transform: 'skewY(0deg)',
      marginTop: '-250px'
    },
  },
  container: {
    backgroundColor: 'transparent',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));
