import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import { Alert } from '@material-ui/lab';
import { Slide, makeStyles } from '@material-ui/core';

export type AlertTypes = 'success' | 'warning' | 'info' | 'error';

type AlertSnackProps = {
  message: string;
  onClose: () => void;
  type?: AlertTypes;
};

const useStyles = makeStyles(theme => ({
  icon: {
    position: 'absolute',
  },
}));

export const AlertSnack = ({ message, onClose, type }: AlertSnackProps) => {
  return (
    <Snackbar
      open={message && message !== ''}
      TransitionComponent={props => <Slide {...props} direction="up" />}
      onClose={() => onClose && onClose()}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      autoHideDuration={3000}
    >
      <Alert severity={type || 'info'}>
        {message}
      </Alert>
    </Snackbar>
  );
};
