import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgDetectMotion = (props: React.SVGProps<SVGSVGElement>) =>
  React.createElement(
    SvgIcon,
    props,
    <path
      d="M11.694 4.875a2.063 2.063 0 100-4.127 2.063 2.063 0 000 4.127zM10.663 22.75c.448 0 .862-.294.991-.748l1.431-4.489a2.412 2.412 0 00-1.07-2.72l-2.228-1.346 1.802-4.503c.117.157.218.326.276.519l.6 1.974a2.395 2.395 0 002.295 1.705l2.08.008h.003a1.031 1.031 0 00.003-2.063l-2.08-.008a.342.342 0 01-.326-.243l-.6-1.973a3.79 3.79 0 00-2.785-2.588l-3.362-.76a3.759 3.759 0 00-3.148.696L2.466 7.817a1.03 1.03 0 101.262 1.631l2.08-1.605a1.73 1.73 0 011.43-.317l.64.145-1.517 3.753a2.397 2.397 0 00.987 2.96l3.6 2.174a.381.381 0 01.166.294.412.412 0 01-.013.095l-1.43 4.489a1.031 1.031 0 00.992 1.314zm-6.41-4.125c.963 0 1.832-.572 2.212-1.458l.582-1.358-.41-.248a3.766 3.766 0 01-1.216-1.196l-.853 1.989a.343.343 0 01-.316.209h-3.22a1.032 1.032 0 000 2.062h3.22z"
      fill="currentColor"
      fillRule="nonzero"
    />
  );

export default SvgDetectMotion;
