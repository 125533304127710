import { handleErrorResponse } from './lib/helpers';
import { API } from 'aws-amplify';
import { Endpoints } from './lib/endpoints';

export default function () {
  return {
    submitInquiry: async (email: string, message: string): Promise<void> => {
      const opts = {
        body: {
          email: email,
          message: message,
        },
      };

      let response;
      try {
        response = await API.post(
          Endpoints.ISCPortalAPI,
          '/utils/submit-inquiry',
          opts
        );

        if (response.data) {
          return response.data;
        }
      } catch {
        return handleErrorResponse(response);
      }
    },
  };
}
