import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router";

type SideMenuItemProps = {
  primary: string;
  secondary?: string;
  icon: JSX.Element;
  href?: string;
  hide?: boolean;
  classes?: string;
  onClick?: () => void;
  setOpen: (value: React.SetStateAction<boolean>) => void
};
export const SideMenuItem = (props: SideMenuItemProps) => {
  const history = useHistory();

  const handleClick = () => {
    if (props.href) {
      history.push(props.href);
    }
    if (props.onClick) {
      props.onClick();
    }
    
    props.setOpen(false);
  };

  return props.hide ? null : (
    <ListItem
      component="li"
      button
      onClick={handleClick}
      className={props.classes}
    >
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText primary={props.primary} secondary={props.secondary} />
    </ListItem>
  );
};
