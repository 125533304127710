import React from 'react';
import { WifiThree, WifiTwo, WifiOne, WifiZero } from '../mui-icons';
import { IconWrapper, StatusIconProps } from './base-wrapper';
import theme from '../../theme';

export const WifiIcon = ({ operationStatus, ...props }: StatusIconProps) => {
  const getIcon = () => {
    switch (operationStatus?.wifi?.strength) {
      case 4:
        return <WifiThree />;
      case 3:
        return <WifiThree />;
      case 2:
        return (
          <WifiTwo
            style={{
              color: theme.palette.warning.main,
            }}
          />
        );
      case 1:
        return (
          <WifiOne
            style={{
              color: theme.palette.error.main,
            }}
          />
        );
      default:
        return (
          <WifiZero
            style={{
              color: theme.palette.error.main,
            }}
          />
        );
    }
  };

  return operationStatus?.wifi?.percentStrength >= 0 ? (
    <IconWrapper
      icon={getIcon()}
      title="Wifi signal"
      label={`${operationStatus?.wifi?.percentStrength}%`}
      color={operationStatus?.wifi?.isWeakSignal ? theme.palette.error.main : theme.palette.text.primary}
      {...props}
    />
  ) : null;
};
