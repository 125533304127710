import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles(() => ({
  profile: {
    whiteSpace: "normal",
    backgroundColor: "transparent !important",
    marginTop: "40px",
    "&:hover, &.active:hover, &:focus": {
      "& div": {
        color: "black",
      },
    },
  },
  profileIcon: {
    fontSize: "30px",
    marginLeft: "-4px",
  },
  navLink: {
    backgroundColor: "transparent !important",
    "&:hover, &.active:hover, &:focus": {
      "& div": {
        color: "black",
      },
    },
  },
}));
