import React, { Fragment } from 'react'
import {Link} from "react-router-dom";
import {Box, Typography, Button} from '@material-ui/core';

import { PageHeader } from '../../components/page-header';

export const Error404 = () => {
  return (
    <Fragment>
      <PageHeader title="Error 404 - Page Not Found" />
      <Box>
        <Box mb={2}>
          <Typography>Oops! The page you're looking for has moved or doesn't exist.</Typography>
        </Box>
        <Button
            color="primary"
            component={Link}
            to="/"
          >
            Go to Cameras
          </Button>
      </Box>
    </Fragment>
  )
}