import React from 'react';
import {ImageProps} from "../../schema";
import {withImageFallback} from "./withImageFallback";
import {useStyles} from "./useStyles";
import clsx from "clsx";

export const Image = (props: ImageProps) => {
  const classes = useStyles();

  return withImageFallback(props, (
    <img
      {...props}
      className={clsx(props.className, classes.img)}
      onError={props.onError}
    />
  ));
};
