import React, {useState} from 'react'
import Typography from "@material-ui/core/Typography";
import {FormControl, IconButton, Input, InputAdornment} from "@material-ui/core";
import {Done, Edit, Close} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: 0
  },
  light: {
    "& *, & *:hover, & *:active, &:before, &:after": {
      color: theme.palette.common.white,
      borderColor: theme.palette.common.white
    }
  }
}));

export type EditableTitleProps = {
  title: string;
  onTitleChange: (title: string) => void;
}

export const EditableTitle = (props: EditableTitleProps) => {
  const {onTitleChange} = props;
  const [title, setTitle] = useState(props.title);
  const [isEditing, setIsEditing] = React.useState(false);
  const classes = useStyles();

  const handleKeyUp = (ev: MouseEvent) => {
    const input = ev.target as HTMLInputElement;
    setTitle(input.value);
  }

  const handleCancel = () => {
    setTitle(props.title);
    setIsEditing(false);
  }

  const handleDoneEditing = () => {
    onTitleChange(title);
    setIsEditing(false);
  }

  return (
    <form action="" onSubmit={(e) => {
      e.preventDefault();
      handleDoneEditing();
    }}>
      { isEditing ?
        <FormControl>
          <Input
            id="searchField"
            type="text"
            fullWidth={true}
            autoFocus={true}
            value={title}
            onChange={(ev: any) => handleKeyUp(ev)}
            className={classes.light}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={handleCancel} aria-label="Cancel">
                  <Close />
                </IconButton>
                <IconButton onClick={handleDoneEditing} aria-label="Done">
                  <Done/>
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        : <Typography variant="h1" className={classes.heading}>
          {title}
          <IconButton className={classes.light} aria-label="Edit" onClick={() => {setIsEditing(true)}}>
            <Edit />
          </IconButton>
        </Typography>
      }
    </form>
  )
}
