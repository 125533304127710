import {
  useContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import AppContext, { AppContextType } from './context';
import { useHistory } from 'react-router';
import { NotFoundException, UnauthorizedException, ApiException, ErrorTypes } from './api/lib/domain';

export type NotFoundSettings = {
  redirect: string;
  message: string;
};

export const handleError = (
  context: AppContextType,
  history: any,
  error: ErrorTypes,
  notFoundRedirect?: string | NotFoundSettings
) => {
  console.log({ error })
  if (error instanceof NotFoundException) {
    if (typeof notFoundRedirect === 'string') {
      context.onAlert('Resource not found!', 'error');
      history.push(notFoundRedirect);
    } else if (notFoundRedirect.redirect && notFoundRedirect.message) {
      context.onAlert(notFoundRedirect.message, 'error');
      history.push(notFoundRedirect.redirect);
    }
    return;
  } else if (error instanceof UnauthorizedException) {
    context.onLogout();
    return;
  } else if (error instanceof ApiException) {
    context.onAlert('An unexpected response received from the server', 'error');
    return;
  }
  throw error;
};

export function useApi<T>(
  executeRequest: () => Promise<T>,
  notFoundRedirect?: string | NotFoundSettings,
  deps?: readonly any[]
): [T | null, Dispatch<SetStateAction<T>>, boolean, () => void] {
  const [reloadCounter, setReloadCounter] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState<T>(null);
  const context = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    executeRequest()
      .then(response => {
        setData(response);
      })
      .catch(error => handleError(context, history, error, notFoundRedirect))
      .finally(() => {
        setLoading(false);
      });
  }, [reloadCounter].concat(deps || []));

  return [
    data,
    setData,
    isLoading,
    () => {
      setReloadCounter(cnt => cnt + 1);
    },
  ];
}

export function useApiUpdate<T, R = void|unknown>(
  updateFunction: (value: T) => Promise<R>,
  onSuccess?: ((result: R) => void) | string
): [(value: T) => Promise<R | void>, boolean] {
  const [isWorking, setWorking] = useState(false);
  const context = useContext(AppContext);
  const history = useHistory();

  const doUpdate = (value: T): Promise<R | void> => {
    setWorking(true);
    return updateFunction(value)
      .then(result => {
        if (typeof onSuccess === 'string') {
          history.push(onSuccess);
        }
        if (typeof onSuccess === 'function') {
          onSuccess(result);
        }
        return result;
      })
      .catch(error => handleError(context, history, error))
      .finally(() => {
        setWorking(false);
      });
  };

  return [doUpdate, isWorking];
}
