import moment = require("moment");

export const formatDate = (dt: Date | string) =>
  moment(dt).format('MM/DD/YYYY')

export const formatMoney = (value: any) =>
  '$' + parseFloat(value.toString()).toFixed(2)

export const formatValue = (value: number, singular: string, plural?: string) =>
  `${value} ${value == 1 ? singular : (plural ? plural : (singular + 's'))}`
