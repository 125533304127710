import React from 'react';
import clsx from 'clsx';
import { useStyles } from './base-styles';
import { OperationStatusSummary } from '../../api/isc-api';

export type StatusIconProps = {
  operationStatus: OperationStatusSummary
  className?: string;
  iconClass?: string;
  labelClass?: string;
}

export type IconWrapperProps = {
  icon: React.ReactNode;
  label: string;
  className?: string;
  iconClass?: string;
  labelClass?: string;
  title?: string;
  color?: string;
} & Pick<StatusIconProps, 'className' | 'iconClass' | 'labelClass'>;

export const IconWrapper = ({
  icon,
  label,
  className,
  iconClass,
  labelClass,
  title,
  color,
}: IconWrapperProps) => {
  const classes = useStyles();
  const getStyle = () => {
    if (!color) return {};

    return { color };
  };

  return (
    <div className={clsx(classes.wrapper, className)} title={title}>
      <span className={clsx(classes.icon, iconClass)}>{icon}</span>
      <span className={clsx(classes.label, labelClass)} style={getStyle()}>
        {label}
      </span>
    </div>
  );
};
