import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    display: 'flex',
    alignItems: 'center'
  },
  label: {
    marginLeft: '5px'
  }
}));
