import * as React from 'react';
import { SVGProps } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgChangeOrganizations = (props: SVGProps<SVGSVGElement>) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M5.01 0a.8.8 0 0 0-.78.82.81.81 0 0 0 .52.73l1.59.61a10.83 10.83 0 0 0 2.3 20.47.8.8 0 0 0 .94-.63.81.81 0 0 0-.63-.94 9.23 9.23 0 0 1-2.1-17.37L6.21 5a.8.8 0 1 0 1.43.71l1.6-3.19a.82.82 0 0 0-.36-1.08H8.8L5.34.06A.81.81 0 0 0 5.01 0Zm8 1.33a.8.8 0 0 0-.14 1.59 9.24 9.24 0 0 1 2.14 17.38l.62-1.3a.8.8 0 0 0-.33-1.08.78.78 0 0 0-.42-.09.77.77 0 0 0-.68.46l-1.6 3.19a.8.8 0 0 0 .41 1.08h.07l3.46 1.33a.8.8 0 1 0 .68-1.44l-.11-.05-1.57-.6A10.84 10.84 0 0 0 13.2 1.35h-.16Zm-2.09 3.74a3.21 3.21 0 0 0-3.2 3.2 3.25 3.25 0 0 0 1 2.29 5.59 5.59 0 0 0-3.63 5.16.79.79 0 0 0 .8.8h3.73a.8.8 0 1 0 0-1.59H6.74a4.19 4.19 0 0 1 4.18-3.2 4.19 4.19 0 0 1 4.18 3.2h-2.85a.8.8 0 1 0 0 1.59h3.76a.8.8 0 0 0 .8-.8 5.59 5.59 0 0 0-3.64-5.16 3.2 3.2 0 0 0-2.22-5.49Zm0 1.6a1.6 1.6 0 1 1-1.6 1.6 1.59 1.59 0 0 1 1.6-1.6Z" />
  );

export default SvgChangeOrganizations;
