import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgUserPortal = (props: React.SVGProps<SVGSVGElement>) =>
  React.createElement(
    SvgIcon,
    props,
    <path
      d="M12.062 13.937a6.47 6.47 0 006.47-6.468A6.47 6.47 0 0012.061 1a6.47 6.47 0 00-6.468 6.469 6.47 6.47 0 006.468 6.468zm0-2.156A4.32 4.32 0 017.75 7.47a4.32 4.32 0 014.312-4.313 4.32 4.32 0 014.313 4.313 4.32 4.32 0 01-4.313 4.312zM19.97 24c1.19 0 2.156-.966 2.156-2.156v-1.15a6.04 6.04 0 00-6.038-6.038c-1.289 0-1.909.719-4.025.719-2.115 0-2.73-.719-4.024-.719A6.04 6.04 0 002 20.694v1.15C2 23.034 2.966 24 4.156 24H19.97zm0-2.156H4.156v-1.15a3.888 3.888 0 013.882-3.882c.655 0 1.72.72 4.024.72 2.323 0 3.365-.72 4.025-.72a3.888 3.888 0 013.882 3.882v1.15z"
      fill="currentColor"
      fillRule="nonzero"
    />
  );

export default SvgUserPortal;
