import React from "react";
import { NavLink } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

type MainMenuItemProps = {
  primary: string;
  href: string;
  hide?: boolean;
  classes?: any;
  onClick?: () => void;
  exact?: boolean
};

export const MainMenuItem = (props: MainMenuItemProps) => {
  return props.hide ? null : (
    <ListItem
      button
      component={NavLink}
      to={props.href}
      exact={props.exact === true}
      activeClassName={props.classes.menuListItemSelected}
      onClick={props.onClick}
      classes={{
        root: props.classes.menuListItem,
      }}
    >
      <ListItemText
        disableTypography
        className={props.classes.menuTextItem}
        primary={props.primary} />
    </ListItem>
  );
};
