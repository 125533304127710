import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgWifiZero = (props: React.SVGProps<SVGSVGElement>) =>
  React.createElement(
    SvgIcon,
    props,
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M21.281 19.807l.614-.79a.5.5 0 00-.088-.701l-4.906-3.792 1.42-1.413a.496.496 0 00-.018-.724 9.474 9.474 0 00-7.198-2.343l-2.6-2.008c3.937-1.2 8.368-.344 11.577 2.595.2.183.508.178.7-.012l1.07-1.061a.497.497 0 00-.011-.718C17.514 4.838 11.315 3.976 6.169 6.23L3.421 4.105a.5.5 0 00-.702.088l-.614.79a.5.5 0 00.088.701l18.386 14.21a.5.5 0 00.702-.087zM3.918 10.63c.219-.2.447-.382.677-.563l-2.03-1.57c-.135.117-.275.22-.406.342a.496.496 0 00-.01.718l1.07 1.06c.191.191.5.195.7.013zm3.518 3.568a6.853 6.853 0 011.349-.892l-2.137-1.651a9.364 9.364 0 00-.95.731.497.497 0 00-.019.724l1.072 1.063a.505.505 0 00.685.025zM12 19c.997 0 1.817-.732 1.968-1.687l-2.771-2.142A1.999 1.999 0 0012 19z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  );

export default SvgWifiZero;
