import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgCameraOnline = (props: React.SVGProps<SVGSVGElement>) =>
  React.createElement(
    SvgIcon,
    props,
    <g fill="currentColor" fillRule="evenodd">
      <path d="M19.03 3c.576 0 1.05.473 1.05 1.05v16.78c0 .577-.474 1.049-1.05 1.049H12.8v-1.872l5.524.001V4.87H5.315v7.63H3.56V4.049c0-.539.412-.986.935-1.043L4.61 3z" />
      <path d="M11.793 14.982a4.123 4.123 0 004.116-4.116 4.107 4.107 0 00-4.116-4.116 4.107 4.107 0 00-4.116 4.116 4.106 4.106 0 004.116 4.116m0-6.743a2.656 2.656 0 012.653 2.651 2.642 2.642 0 01-2.653 2.654A2.657 2.657 0 019.14 10.89a2.642 2.642 0 012.653-2.652M7.793 6.394c0 .908-1.363.908-1.363 0 0-.91 1.363-.91 1.363 0M17.156 6.394c0 .908-1.363.908-1.363 0 0-.91 1.363-.91 1.363 0" />
      <g fillRule="nonzero">
        <path d="M11.27 21.695c-.156-4.004-3.392-7.238-7.394-7.394a.264.264 0 00-.274.266l.004.8a.27.27 0 00.258.268 6.37 6.37 0 016.072 6.072.27.27 0 00.268.259l.8.003c.15 0 .272-.124.266-.274zm-6.01-1.383a1.062 1.062 0 10-1.504 1.502 1.062 1.062 0 001.503-1.502zm3.36 1.398a5.097 5.097 0 00-4.759-4.758.262.262 0 00-.278.265l.005.803c.001.14.11.258.25.268a3.74 3.74 0 013.445 3.446.27.27 0 00.268.249l.803.005a.262.262 0 00.266-.278z" />
      </g>
    </g>
  );

export default SvgCameraOnline;
