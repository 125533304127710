import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useStyles } from './useStyles';

export type Breadcrumb = {
  label?: string;
  href?: string;
};

type PageHeaderBreadcrumbsProps = {
  breadcrumbs: Breadcrumb[];
  breadcrumbTitle?: string;
};

export const PageHeaderBreadcrumbs = ({
  breadcrumbs,
  breadcrumbTitle,
}: PageHeaderBreadcrumbsProps) => {
  const classes = useStyles();

  if (!breadcrumbs) return null;

  return (
    <Breadcrumbs
      className={classes.breadcrumb}
      separator={<ChevronRightIcon className={classes.breadcrumbIcon} />}
      aria-label="breadcrumb"
      maxItems={5}
      itemsBeforeCollapse={1}
      itemsAfterCollapse={4}
    >
      {breadcrumbs.map(breadcrumb =>
        breadcrumb.hasOwnProperty('href') ? (
          <Link
            color="inherit"
            to={breadcrumb.href}
            className={classes.breadcrumbLink}
          >
            {breadcrumb.label}
          </Link>
        ) : (
          <span className={classes.breadcrumbLink}>{breadcrumb.label}</span>
        )
      )}

      <Typography color="textPrimary" className={classes.breadcrumbLink}>
        {breadcrumbTitle}
      </Typography>
    </Breadcrumbs>
  );
};
