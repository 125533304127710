import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgUsers = (props: React.SVGProps<SVGSVGElement>) =>
  React.createElement(
    SvgIcon,
    props,
    <path
      d="M7.75 11.875a3.935 3.935 0 003.937-3.938A3.935 3.935 0 007.75 4a3.935 3.935 0 00-3.938 3.937 3.935 3.935 0 003.938 3.938zm10.125 0a3.376 3.376 0 10-.002-6.752 3.376 3.376 0 00.002 6.752zM7.75 10.187a2.252 2.252 0 01-2.25-2.25 2.252 2.252 0 012.25-2.25A2.252 2.252 0 0110 7.937a2.252 2.252 0 01-2.25 2.25zm10.125 0a1.688 1.688 0 110-3.375 1.688 1.688 0 010 3.375zm-5.063 9.563c.932 0 1.688-.756 1.688-1.688v-1.575c0-.836-.253-1.613-.69-2.26-.727-1.08-1.968-1.649-3.244-1.649-.418 0-.84.06-1.248.186-.5.152-1.023.236-1.572.236a5.397 5.397 0 01-1.571-.236 4.33 4.33 0 00-1.248-.186c-1.276 0-2.517.57-3.245 1.649A4.079 4.079 0 001 16.487v1.575c0 .932.756 1.688 1.687 1.688h10.125zm9.282-1.125c.777 0 1.406-.63 1.406-1.406v-1.312c0-.7-.211-1.346-.573-1.884-.608-.9-1.642-1.375-2.704-1.375-.977 0-1.223.352-2.348.352-1.125 0-1.371-.352-2.348-.352-.468 0-.922.106-1.343.285.203.208.397.422.562.665.165.246.302.506.422.773.116-.025.235-.039.359-.039.604 0 1.04.352 2.348.352 1.315 0 1.74-.352 2.348-.352.552 0 1.037.236 1.305.63.186.277.284.6.284.938v1.037h-6.187v1.125c0 .194-.021.38-.056.563h6.525zm-9.278-.563H2.687v-1.575a2.37 2.37 0 011.55-2.218 1.64 1.64 0 011.122 0 7.088 7.088 0 002.391.418c.809 0 1.61-.14 2.394-.418a1.64 1.64 0 011.122 0 2.367 2.367 0 011.55 2.218v1.575z"
      fill="currentColor"
      fillRule="nonzero"
    />
  );

export default SvgUsers;
