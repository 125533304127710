import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgSettings = (props: React.SVGProps<SVGSVGElement>) =>
  React.createElement(
    SvgIcon,
    props,
    <path
      d="M14.699 20.144a.844.844 0 00.627-.815v-1.292a8.306 8.306 0 001.157-.67l1.117.646a.844.844 0 001.02-.134 8.704 8.704 0 002.251-3.895.845.845 0 00-.393-.951l-1.12-.646a6.947 6.947 0 000-1.336l1.12-.646a.845.845 0 00.393-.952 8.704 8.704 0 00-2.252-3.895.844.844 0 00-1.019-.134l-1.117.646a8.307 8.307 0 00-1.157-.669V4.109a.89.89 0 00-.627-.816 8.775 8.775 0 00-4.498 0 .844.844 0 00-.627.816V5.4a8.307 8.307 0 00-1.157.669L7.3 5.424a.844.844 0 00-1.019.134A8.704 8.704 0 004.03 9.453c-.1.37.062.76.393.952l1.12.646a8.375 8.375 0 00-.033.668c0 .185.015.484.032.668l-1.12.646a.845.845 0 00-.392.951 8.704 8.704 0 002.252 3.895c.27.27.688.325 1.019.134l1.117-.645c.302.215.82.515 1.157.668v1.293a.89.89 0 00.627.815 8.775 8.775 0 004.498 0zm-2.249-1.393c-.33 0-.863-.045-1.188-.101v-1.779c-1.39-.49-1.554-.585-2.678-1.548l-1.541.89c-.426-.512-.959-1.433-1.19-2.058l1.54-.889c-.268-1.452-.268-1.642 0-3.095l-1.54-.889a7.038 7.038 0 011.19-2.057l1.54.89c1.125-.964 1.29-1.058 2.679-1.549V4.788c.325-.056.858-.101 1.188-.101.33 0 .863.045 1.189.1v1.78c1.389.49 1.553.584 2.677 1.547l1.541-.89c.426.512.96 1.434 1.19 2.058l-1.54.89c.268 1.451.268 1.642 0 3.094l1.54.89c-.23.624-.764 1.545-1.19 2.057l-1.54-.89c-1.125.963-1.29 1.058-2.678 1.548v1.779c-.326.056-.858.1-1.189.1zm0-3.657a3.379 3.379 0 003.375-3.375 3.379 3.379 0 00-3.375-3.375 3.379 3.379 0 00-3.375 3.375 3.379 3.379 0 003.375 3.375zm0-1.688a1.69 1.69 0 01-1.687-1.687c0-.93.757-1.688 1.687-1.688s1.688.757 1.688 1.688a1.69 1.69 0 01-1.688 1.687z"
      fill="currentColor"
      fillRule="nonzero"
    />
  );

export default SvgSettings;
