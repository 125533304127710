import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgSdCardEmpty = (props: React.SVGProps<SVGSVGElement>) =>
  React.createElement(
    SvgIcon,
    props,
    <path
      d="M5.149 9.325l1.575 1.172V18c0 .244.187.45.432.492l.094.008H17.47l1.506 1.122c-.306.21-.673.344-1.07.373L17.75 20H7.25c-1.158 0-2.1-.897-2.1-2l-.001-8.675zM2.443 4.188a.543.543 0 01.738-.078l19.622 14.61a.484.484 0 01.082.702l-.328.39a.543.543 0 01-.738.078L2.197 5.28a.484.484 0 01-.082-.702zM17.75 4c1.158 0 2.1.897 2.1 2l-.001 8.673-1.575-1.172V6a.51.51 0 00-.43-.492L17.75 5.5h-7.747L8.916 6.534l-1.25-.931L9.35 4zm-.525 2.5v3H15.65v-3h1.575zm-2.625 0v3h-1.575v-3H14.6zm-2.625 0l-.001 2.311-1.575-1.173V6.5h1.576z"
      fill="currentColor"
      fillRule="nonzero"
    />
  );

export default SvgSdCardEmpty;
