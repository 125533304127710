import Amplify, { Hub } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useEffect } from 'react';
import { AppContextType } from '../../context';
import awsExports from '../../aws-exports';
import { ENABLE_DEV_CONSOLE } from '../../util/globals';

Amplify.configure(awsExports);

/**
 * Amplify Listener is reactive, and connects Amplify to
 * our custom login flow.
 *
 * Auth flow:
 * 1- login from login page
 * 2- redirects to amplify
 * 3- on success, redirects back to app
 * 4- the hub listener below picks up the 'signIn' event
 * 5- update context.authStatus to AttemptingOidcLogin
 */

export const useAmplifyListener = (context: AppContextType) => {
  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          context.onAmplifyAuthenticated(data as CognitoUser);
          break;
        case 'signOut':
          context.onLogout();
          break;
      }
    });
  });
};
