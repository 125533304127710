import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgDetectVehicleMatch = (props: React.SVGProps<SVGSVGElement>) =>
  React.createElement(
    SvgIcon,
    props,
    <defs>
      <path
        d="M7.038 0c-.24 0-.469.102-.637.28L.244 6.908c-.04.045-.064.1-.095.151-.027.043-.062.08-.082.128A.936.936 0 000 7.534v4.895c0 1.578 1.25 2.862 2.787 2.862h.48C3.558 17.383 5.294 19 7.408 19s3.85-1.617 4.138-3.71h12.839c.288 2.093 2.025 3.71 4.138 3.71 2.115 0 3.851-1.617 4.138-3.71h.847c1.373 0 2.493-1.146 2.493-2.56l-.002-4.445a.898.898 0 00-.768-.896l-8.792-1.166-5.8-5.958A.869.869 0 0020.016 0H7.038zm9.898 1.808h2.715l4.252 4.368h-6.967V1.808zm-6.623.112c.019-.036.019-.074.03-.11v-.002h4.834v4.368H8.186l2.127-4.256zM8.39 1.81L5.962 6.668a.927.927 0 00.032.883c.16.27.443.434.752.434H25.97l8.267 1.097v3.647c0 .415-.329.753-.732.753h-.977c-.51-1.788-2.104-3.106-4.01-3.106-1.904 0-3.499 1.318-4.01 3.106H11.549c-.044 0-.083.02-.125.027-.503-1.804-2.104-3.133-4.017-3.133-1.912 0-3.51 1.326-4.016 3.127-.032-.004-.058-.02-.09-.02h-.513c-.567 0-1.028-.473-1.028-1.055V8.437h1.98a.89.89 0 00.88-.903.89.89 0 00-.88-.904h-.802l4.478-4.82h.975zm17.69 12.878c0-1.38 1.094-2.503 2.44-2.503 1.344 0 2.437 1.122 2.437 2.503 0 1.38-1.093 2.504-2.438 2.504-1.343 0-2.438-1.123-2.438-2.504zm-21.113 0c0-1.38 1.093-2.503 2.44-2.503 1.343 0 2.436 1.122 2.436 2.503 0 1.38-1.095 2.504-2.437 2.504-1.345 0-2.44-1.123-2.44-2.504z"
        id="detect-vehicle-match_svg__a"
      />
      <path
        d="M.783 0C.348 0 0 .448 0 1c0 .553.35 1 .783 1h1.435C2.653 2 3 1.553 3 1c0-.552-.35-1-.782-1H.783z"
        id="detect-vehicle-match_svg__c"
      />
    </defs>,
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 9)">
        <mask id="detect-vehicle-match_svg__b" fill="#fff">
          <use xlinkHref="#detect-vehicle-match_svg__a" />
        </mask>
        <path
          fill="#ED380C"
          mask="url(#detect-vehicle-match_svg__b)"
          d="M-4.959 24.08H40.96V-5.08H-4.96z"
        />
      </g>
      <g transform="translate(16 18)">
        <mask id="detect-vehicle-match_svg__d" fill="#fff">
          <use xlinkHref="#detect-vehicle-match_svg__c" />
        </mask>
        <path
          fill="#ED380C"
          mask="url(#detect-vehicle-match_svg__d)"
          d="M-4.547 6.545H7.548v-11.09H-4.547z"
        />
      </g>
      <g fill="#ED380C">
        <path d="M26.941 6.44c2.194.434 4.081-1.536 3.666-3.826C30.375 1.34 29.379.3 28.16.06c-2.194-.433-4.081 1.536-3.666 3.826.231 1.272 1.228 2.313 2.446 2.553M29.73 7.312h-.405a4.1 4.1 0 01-1.774.407 4.103 4.103 0 01-1.773-.407h-.406c-.837 0-1.596.334-2.172.877-.268.251-.262.695-.008.962l3.283 3.425c.26.271.612.424.98.424h4.377c.645 0 1.168-.545 1.168-1.218v-1.057c0-1.884-1.464-3.413-3.27-3.413" />
      </g>
    </g>
  );

export default SvgDetectVehicleMatch;
