import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(theme => ({
  img: {
    width: '100%',
  },
  skeletonWrap: {
    width: '100%',
    position: 'relative',
    paddingTop: '75%',
  },
  skeleton: {
    backgroundColor: theme.palette.grey[100],
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    padding: '0 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  skeletonTypography: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  zoomContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  },
  zoomImageWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'block',
    height: 'auto',
    width: 'auto'
  },
  zoomTools: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
  zoomButton: {
    color: theme.palette.common.white,
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(1),
    background:  'rgba(64, 60, 61, 0.4)',
    '&:hover': {
      background: 'rgba(64, 60, 61, 0.6)',
    },
  },
}));
