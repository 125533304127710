import { makeStyles, Box, Typography, Button } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { Add, Settings } from '@material-ui/icons';

export const useStyles = makeStyles(theme => ({
  fallbackButton: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  fallbackIcon: {
    fontSize: 16,
    color: theme.palette.grey[500],
    marginRight: '10px',
  },
  emptyText: {
    marginBottom: theme.spacing(4),
  },
}));

type EmptyFallbackProps = {
  text: ReactNode;
  buttonText?: string;
  buttonSize?: 'small' | 'medium' | 'large';
  onAction?: () => void;
  showButton?: boolean;
  icon?: 'add' | 'settings';
};

export const EmptyFallback = ({
  text,
  icon = 'add',
  buttonText = 'Add New',
  buttonSize = 'large',
  onAction,
  showButton = true,
}: EmptyFallbackProps) => {
  const classes = useStyles();

  const getIcon = () => {
    switch (icon) {
      case 'settings':
        return <Settings className={classes.fallbackIcon} />;
      case 'add':
        return <Add className={classes.fallbackIcon} />;
    }
  };

  const getButton = () => (
    <Button
      className={classes.fallbackButton}
      color="secondary"
      variant="outlined"
      size={buttonSize}
      onClick={onAction}
    >
      {getIcon()} {buttonText}
    </Button>
  );

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      py={8}
      px={2}
    >
      <Typography className={showButton ? classes.emptyText : null}>{text}</Typography>
      {showButton && getButton()}
    </Box>
  );
};

EmptyFallback.Button = () => {};
