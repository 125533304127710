import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgLogout = (props: React.SVGProps<SVGSVGElement>) =>
  React.createElement(
    SvgIcon,
    props,
    <path
      d="M8.437 21c.31 0 .563-.253.563-.563v-1.125a.564.564 0 00-.563-.562H4.5a2.25 2.25 0 01-2.25-2.25v-9A2.25 2.25 0 014.5 5.25h3.937c.31 0 .563-.253.563-.563V3.562A.564.564 0 008.437 3H4.5A4.501 4.501 0 000 7.5v9C0 18.984 2.016 21 4.5 21h3.937zm7.693-.919l7.706-7.687a.563.563 0 000-.797L16.13 3.909a.567.567 0 00-.797.005l-.919.919a.556.556 0 00.01.801l5.3 5.147H8.063a.564.564 0 00-.562.563v1.312c0 .31.253.563.562.563h11.663l-5.302 5.142a.564.564 0 00-.009.801l.919.92c.22.22.576.22.797 0z"
      fill="currentColor"
      fillRule="nonzero"
    />
  );

export default SvgLogout;
