import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgBatteryLow = (props: React.SVGProps<SVGSVGElement>) =>
  React.createElement(
    SvgIcon,
    props,
    <path
      d="M20.375 17c.87 0 1.575-.672 1.575-1.5V15h.262c.435 0 .788-.336.788-.75v-4.5c0-.414-.353-.75-.788-.75h-.262v-.5c0-.828-.705-1.5-1.575-1.5h-16.8C2.705 7 2 7.672 2 8.5v7c0 .828.705 1.5 1.575 1.5h16.8zm0-1.5h-16.8v-7h16.8v2h1.05v3h-1.05v2zM9.35 14v-4h-4.2v4h4.2z"
      fill="currentColor"
      fillRule="nonzero"
    />
  );

export default SvgBatteryLow;
