import React from 'react';
import {
  Container,
  CssBaseline,
  Typography,
  makeStyles,
  TextField,
  Button,
} from '@material-ui/core';
import { AlertSnack } from '../../components/alert-snack';
import AppContext from '../../context';
import utils from '../../api/utils';
import {useHistory} from "react-router";

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const SubmitPage = () => {
  const classes = useStyles({});
  const history = useHistory();
  const context = React.useContext(AppContext);

  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');

  const handleSubmit = async () => {
    if (message) {
      utils()
        .submitInquiry(email, message)
        .then(
          () => {
            context.onAlert('Thank you for contacting us!', 'success');
            history.push('/login');
          },
          () => {
            context.onAlert('Failed to send an email', 'error');
            history.push('/login');
          }
        );
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Spotlight Security Inquiry
        </Typography>
        <>
          <p>
            If you have any security inquiries regarding our technology,
            please submit them using the form below.
          </p>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label="Your Email Address (optional)"
            name="email"
            value={email}
            onChange={ev => setEmail(ev.target.value)}
            autoComplete="email"
            autoFocus
          />
          <TextField
            multiline
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="message"
            label="Your message"
            name="message"
            rows={6}
            value={message}
            onChange={ev => setMessage(ev.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
            disabled={!message}
          >
            Submit
          </Button>
        </>
      </div>
      <AlertSnack
        message={context.alert?.message}
        type={context.alert?.type}
        onClose={context.onAlert}
      />
    </Container>
  );
};
