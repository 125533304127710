import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgSdSolid = (props: React.SVGProps<SVGSVGElement>) =>
  React.createElement(
    SvgIcon,
    props,
    <path
      d="M16 20c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-6L6 8v10c0 1.103.897 2 2 2h8zM11 9H9.5V6H11v3zm2.5 0H12V6h1.5v3zM16 9h-1.5V6H16v3z"
      fill="currentColor"
      fillRule="nonzero"
    />
  );

export default SvgSdSolid;
