import {ImageProps} from "../../schema";
import React, {useEffect, useState} from "react";
import {useStyles} from "./useStyles";
import clsx from "clsx";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import {Typography} from "@material-ui/core";

export type ImageFallbackProps = {
  className: string,
  onError: (_:any) => void
}

export function withImageFallback(
  props: ImageProps,
  children: React.ReactElement
) {
  const [isImageFallback, setIsImageFallback] = useState(!props.src);
  const classes = useStyles();

  props.onError = () => {
    setIsImageFallback(true);
  }

  useEffect(() => {
    if (!props.src) {
      setIsImageFallback(true);
    }
  }, [props.src]);

  if (isImageFallback) {
    /* Fallback presentation: return a broken image placeholder */
    return (
      <div className={clsx(props.className, classes.skeletonWrap)}>
        <div className={classes.skeleton}>
          <CancelPresentationIcon color="disabled" fontSize="large"/>
          {props.width > 180 &&
            <Typography className={classes.skeletonTypography} variant="caption">
              Image Unavailable
            </Typography>
          }
        </div>
      </div>
    );
  }

  /* Standard presentation: returned the wrapped component (Image or ZoomImage) */
  return children;
}
